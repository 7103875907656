import { ReactElement } from 'react';
import { LocalBusinessJsonLd } from 'next-seo';
import { useRouter } from 'next/router';
import { IPageLocationFields } from 'types/contentful';
import { convertTime12to24, structuredPhoneNumber } from 'lib/util';
import { locationId } from './SchemaOrg';

interface LocationData {
  location: IPageLocationFields;
  siteName: string;
  domain: string;
  description: string;
}

export default function LocationData({
  location,
  siteName,
  domain,
  description,
}: LocationData): ReactElement {
  const { asPath } = useRouter();
  const [state] = location.state.split('-');
  const hours = Object.entries(location.hours).map(([day, data]) => ({
    opens: convertTime12to24(data.open),
    closes: convertTime12to24(data.close),
    dayOfWeek: day,
  }));

  return (
    <LocalBusinessJsonLd
      id={locationId(domain)}
      description={description}
      type="MedicalClinic"
      name={siteName}
      url={`https://${domain}${asPath}`}
      telephone={structuredPhoneNumber(location.phoneNumber)}
      address={{
        streetAddress: `${location.address1}${location.address2 ? ` ${location.address2}` : ''}`,
        addressLocality: location.city,
        addressRegion: state,
        postalCode: location.zipCode,
        addressCountry: 'US',
      }}
      geo={{
        latitude: location.map.lat.toString(),
        longitude: location.map.lon.toString(),
      }}
      images={[`https:${location.officePhoto?.fields.file.url}` || '']}
      openingHours={hours}
      priceRange="$$"
    />
  );
}
