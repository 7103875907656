import { ReactElement } from 'react';
import { IFragmentLocationCalloutFields, IPageLocationFields } from 'types/contentful';
import Image from './Image';
import Link from './Link';

interface Office extends IPageLocationFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  brand: string;
}

interface LocationCalloutProps extends IFragmentLocationCalloutFields {
  office: Office;
  index: number;
}

const TOKENS = ['brand', 'city', 'state', 'street', 'name'];

export default function LocationCallout({
  office,
  heading,
  copy,
  link,
  icon,
  index,
}: LocationCalloutProps): ReactElement {
  office.street = office.address1;

  const hydratedCopy = TOKENS.reduce(
    (acc, token) => acc.replace(new RegExp(`{${token}}`, 'gi'), office[token] as string),
    copy
  );

  const hydratedHeading = link ? (
    <Link slug={link}>
      <a>
        <h2 className="text-lg font-heading font-bold my-3">
          {office.name} {heading}
        </h2>
      </a>
    </Link>
  ) : (
    <h2 className="text-lg font-heading font-bold my-3">
      {office.name} {heading}
    </h2>
  );

  return (
    <div className="flex flex-col w-full md:w-1/3">
      {icon && (
        <Image
          image={icon}
          query="w=47&h=47"
          classNames="hidden md:block md:ml-10 w-calloutIcon h-calloutIcon"
        />
      )}
      <div
        className={`flex flex-col flex-auto md:pl-10 md:pr-16 mt-4 md:pb-4 pt-5 md:pt-0 ${
          index > 0 ? 'md:border-l border-t md:border-t-0 border-black' : ''
        }`}
      >
        {hydratedHeading}
        <p className="text-sm leading-6">{hydratedCopy}</p>
      </div>
    </div>
  );
}
