import { ReactElement } from 'react';
import { IFragmentService } from 'types/contentful';
import Link from './Link';

interface ServicesProps {
  heading: string;
  services: IFragmentService[];
}

export default function Services({ heading, services }: ServicesProps): ReactElement {
  return (
    <div className="my-6 px-4 lg:px-16">
      <h2 className="font-heading text-base lg:text-xl">{heading}</h2>
      <div className="flex flex-wrap w-full md:w-5/6 lg:w-1/2 mt-4">
        {services.map((service, i) =>
          service.fields.slug ? (
            <Link key={i + service.fields.service} slug={service.fields.slug}>
              <a className="w-1/2 md:w-1/2 my-2 text-sm md:text-lg underline">
                {service.fields.service}
              </a>
            </Link>
          ) : (
            <span key={i} className="w-1/2 md:w-1/2 my-2 text-sm md:text-lg ">
              {service.fields.service}
            </span>
          )
        )}
      </div>
    </div>
  );
}
